import { Department } from '../../shared/typings/department.type';
import { File } from '../../shared/typings/file';
import { PaginationDto } from '../../shared/typings/pagination.dto';
import { Entity } from '../entities/entities.type';
import { ApplyingFrom } from '../notifications/notifications.type';
import { EmploymentStatus, VisaRequestCountry } from '../visa-requests/visa-requests.type';

export enum DeviceEnum {
  DESKTOP = 'desktop',
  IOS = 'ios',
  ANDROID = 'android'
}

export enum UserRole {
  SUPER_ADMIN = 'super.admin',
  ADMIN = 'admin',
  OPERATION_MANAGER = 'operation.manager',
  DEPARTMENT_MANAGER = 'department.manager',
  DEPARTMENT_EMPLOYEE = 'department.employee',
  ENTITY_OWNER = 'entity.owner'
}

export enum UserRoleValues {
  'super.admin' = 'Super Admin',
  'admin' = 'Admin',
  'operation.manager' = 'Operation Manager',
  'department.manager' = 'Department Manager',
  'department.employee' = 'Department Employee'
}

export class LastLoginDetails {
  lastLoginAt?: Date;
  lastLoginDevice?: DeviceEnum;
  platformDetails?: object;
}

export class LocationType {
  type: string;
  coordinates: number[];
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  department?: Department;
  role: UserRole;
  verifiedPhone?: string;
  unverifiedPhone?: string;
  countryCode?: string;
  country?: string;
  email: string;
  avatar?: File;
  lastLoginDetails: LastLoginDetails;
  isBlocked: boolean;
  isNormand: boolean;
  createdAt: Date;
  isActive: boolean;
  token?: string;
  employmentStatus?: EmploymentStatus;
  interestedEmbassies?: VisaRequestCountry[];
  applyingEmbassiesInterestFrom?: ApplyingFrom;
  entities?: Entity[];
}

export enum UserType {
  CUSTOMER = 'customer',
  EMPLOYEE = 'employee'
}

export class UsersQueryDto extends PaginationDto {
  keyword?: string;
  userType?: UserType;
}
